<template>
  <div :class="{
    'audio-container': true,
    'audio-container--hidden': src === null
  }">
    <video ref="videoPlayer" class="video-js" :tech-order="techOrder" :autoplay="true" :controls="true" :height="500"
      :preload="true" @changed="playerStateChanged($event)"></video>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm";
import "videojs-youtube";
import notify from "@/services/libs/notificacao";

export default {
  name: "LessonPlayAudio",
  props: ["porcentagem", "source"],
  data() {
    return {
      autoplay: true,
      player: null,
      techOrder: "html5",
      type: "audio/mpeg",
      src: "",
      audios: [],
      audioSelected: 0,
    };
  },
  computed: {},
  methods: {
    selectAudio(index) {
      this.src = this.audios[index].cdn_url;
      this.audioSelected = index;
      var myPlayer = videojs(this.$refs.videoPlayer);

      myPlayer.src([{ type: this.type, src: this.src }]);
      let cover = this.audios[index].cover;
      if (cover) {
        myPlayer.poster(this.audios[index].cover);
        return;
      }
      myPlayer.poster(
        "https://nyc3.digitaloceanspaces.com/club.greenn/media/1/background-313415.jpg"
      );
    },
    playerDispose() {
      this.videoPlayer.dispose();
    },
    playerGetTime() {
      let duration = this.videoPlayer.currentTime();
      let minutes = Math.floor(duration / 60);
      let seconds = duration - minutes * 60;
      let time = `${minutes} minutos e ${seconds}`;

      //console.log("yt - tempo atual convertido", time);
      //console.log("yt - tempo atual em segundos", duration);

      let duration2 = this.videoPlayer.duration();
      let minutes2 = Math.floor(duration2 / 60);
      let seconds2 = duration2 - minutes2 * 60;
      let time2 = `${minutes2} minutos e ${seconds2}`;

      //console.log("yt - duração total convertida", time2);
      //console.log("yt - duração total em segundos", duration2);
    },
    getPlayer() {
      this.videoPlayer = videojs(
        this.$refs.videoPlayer,
        this.options,
      );
    },
    play() {
      this.videoPlayer.play();
    },
    pause() {
      this.videoPlayer.pause();
    },
    async calculaPorcentagem(type) {
      var current = await this.videoPlayer.currentTime();
      var total = await this.videoPlayer.duration();
      current = parseInt(current, 10);
      total = parseInt(total, 10);
      var calc = (current / total) * 100;
      calc = parseInt(calc, 10);
      //console.log("resultado calculo:", calc);
      if (calc >= parseInt(this.porcentagem, 10)) {
        //console.log("emite evento para liberar os btn", type);
        if (type === "concluir") {
          this.$root.$emit("liberaBtn");
        } else if (type === 'calcula') {
          this.$root.$emit('calculaBtn');
        } else {
          this.$root.$emit("liberaBtnNext");
        }
      } else {
        //console.log("caiu no else ??????");
        notify(
          "erro",
          "Você precisa atingir uma determinada parte da aula para concluir!"
        );
        //console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx youtube");
      }
    },
    async load() {
      const data = this.source;
      if (!data) {
        return
      }
      this.audios = data.audios;
      var myPlayer = videojs(this.$refs.videoPlayer);

      if (this.audios.length === 0) {
        myPlayer.src([{ type: this.type, src: "" }]);
        this.videoPlayer.pause();
        myPlayer.poster("");
        return;
      }
      this.getPlayer();
      this.techOrder = data.mediaType;
      if (this.techOrder === "audio") {
        this.src = data.audios[0].cdn_url;
        this.audioSelected = 0;
        myPlayer.src([{ type: this.type, src: this.src }]);
        let cover = data.audios[0].cover;
        if (cover) {
          myPlayer.poster(data.audios[0].cover);
          return;
        }
        myPlayer.poster(
          "https://nyc3.digitaloceanspaces.com/club.greenn/media/1/background-313415.jpg"
        );
      }
    }
  },
  watch: {
    source() {
      this.load();
    },
  },
  mounted() {
    this.load();
    this.getPlayer();
    this.$root.$on("clicouConcluir", (data) => {
      if (data === "youtube") {
        //console.log("chama função q calcula");
        this.calculaPorcentagem("concluir");
      }
    });
    this.$root.$on("clicouNext", (data) => {
      if (data === "youtube") {
        this.calculaPorcentagem("next");
      }
    });
    this.$root.$on('calcula', data => {
      if (data === 'youtube') {
        this.calculaPorcentagem('calcula');
      }
    });
  },
  beforeDestroy() {
    this.videoPlayer.dispose();
  },
};
</script>
<style lang="scss" scoped>
.video-js {
  width: 100% !important;
  // height: 100% !important;
  height: 47vh !important;
  z-index: 99;
}

.vjs-error-display.vjs-modal-dialog {
  display: none;
}

.toZindex .video-js {
  z-index: 99999999999999999;
}

.audio-container {
  // height: 32rem;

  &--hidden {
    display: none !important;
  }
}

.video-js .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible;
}

.video-js .vjs-big-play-button {
  left: 45% !important;
  top: 48% !important;
}

.relativePosicao,
.removeanimeminplayer {
  display: inline-block !important;
}

.audios {
  list-style-type: none;
  width: 100%;
  //height: 100px;
  overflow: auto;
  margin-top: 10px;
  padding: 0 10px;

  .active {
    border: 1px solid var(--maincolor) !important;
  }

  li {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 30px;
    height: 55px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #81858e;
    font-family: Inter;
    padding: 17px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
